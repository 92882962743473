import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';

const items = [
    { key: '1', label: 'VIPS Plaza Bella' },
];

const DropDownPickUp = ({ onSelect }) => {
    const [selectedItem, setSelectedItem] = useState('Seleccionar');

    const handleMenuClick = (e) => {
        const selectedLabel = items.find(item => item.key === e.key).label;
        setSelectedItem(selectedLabel);
        onSelect(selectedLabel); // Envía la selección al padre
    };

    return (
        <Dropdown
            menu={{
                items,
                onClick: handleMenuClick,
            }}
        >
            <Typography.Link>
                <Space>
                    {selectedItem}
                    <DownOutlined />
                </Space>
            </Typography.Link>
        </Dropdown>
    );
};

export default DropDownPickUp;