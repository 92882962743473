import React, { useState, useEffect } from 'react';
import { supabase } from './components/components/supabaseClient';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Upload,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const EditProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newProduct, setNewProduct] = useState({ name: '', price: '', pieces: '', image: null });
  const [editingProduct, setEditingProduct] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Iniciando la consulta de productos...");
        const { data, error } = await supabase.from('products').select('*');
        if (error) {
          setError('Error fetching products');
          console.error('Error fetching products:', error);
        } else {
          console.log("Productos obtenidos:", data);
          setProducts(data);
        }
      } catch (err) {
        console.error("Error durante la consulta:", err);
        setError('Error fetching products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleAddProduct = async () => {
    if (!newProduct.image) {
        console.error("No se ha seleccionado un archivo de imagen");
        return;
    }

    try {
        const file = newProduct.image;
        let imageUrl = '';

        if (file) {
            console.log("Iniciando la carga de imagen...");
            const { data: uploadData, error: uploadError } = await supabase.storage
                .from('product-images')
                .upload(`${Date.now()}_${file.name}`, file);

            if (uploadError) {
                console.error('Error al cargar el archivo:', uploadError);
                return;
            }

            if (uploadData && uploadData.path) {
                const { data: publicUrlData } = supabase
                    .storage
                    .from('product-images')
                    .getPublicUrl(uploadData.path);

                imageUrl = publicUrlData.publicUrl;

                if (!imageUrl) {
                    console.error('No se pudo obtener una URL pública válida.');
                    return;
                }

                console.log('URL de la imagen obtenida:', imageUrl);
            } else {
                console.error('No se pudo obtener el path de la imagen subida.');
                return;
            }
        }

        const { data: insertedData, error: insertError } = await supabase
            .from('products')
            .insert([{ 
                name: newProduct.name, 
                price: newProduct.price, 
                pieces: newProduct.pieces, 
                image_url: imageUrl 
            }]);

        if (insertError) throw insertError;

        if (insertedData && insertedData.length > 0) {
            setProducts([...products, insertedData[0]]);
            console.log('Producto agregado:', insertedData[0]);
        } else {
            console.error('No se insertaron los datos del producto.');
        }

        setNewProduct({ name: '', price: '', pieces: '', image: null });
        message.success('Producto agregado exitosamente');
        form.resetFields();  // Resetear el formulario después de agregar
    } catch (error) {
        console.error('Error adding product:', error);
        setError('Error adding product');
        message.error('Error al agregar el producto');
    }
  };

  const handleFileChange = (e) => {
    setNewProduct({ ...newProduct, image: e.target.files[0] });
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
    setNewProduct({ name: product.name, price: product.price, pieces: product.pieces, image: null });
    form.setFieldsValue({ name: product.name, price: product.price, pieces: product.pieces }); // Prellenar los campos del formulario
  };

  const handleUpdateProduct = async () => {
    if (editingProduct) {
      try {
        const updates = {
          name: newProduct.name,
          price: newProduct.price,
          pieces: newProduct.pieces,
        };

        if (newProduct.image) {
          const { data, error: uploadError } = await supabase.storage
            .from('product-images')
            .upload(`${Date.now()}_${newProduct.image.name}`, newProduct.image);

          if (uploadError) {
            console.error('Error uploading file:', uploadError);
            return;
          }

          const { publicURL, error: urlError } = supabase
            .storage
            .from('product-images')
            .getPublicUrl(data.path);

          if (urlError) {
            console.error('Error obtaining public URL:', urlError);
            return;
          }

          updates.image_url = publicURL;
        }

        const { error } = await supabase
          .from('products')
          .update(updates)
          .eq('id', editingProduct.id);

        if (error) throw error;

        setProducts(products.map(product => product.id === editingProduct.id ? { ...product, ...updates } : product));
        setEditingProduct(null);
        setNewProduct({ name: '', price: '', pieces: '', image: null });
        form.resetFields();  // Resetear el formulario después de actualizar
        message.success('Producto actualizado exitosamente');
      } catch (error) {
        console.error('Error updating product:', error);
        setError('Error updating product');
        message.error('Error al actualizar el producto');
      }
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const { error } = await supabase
        .from('products')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setProducts(products.filter(product => product.id !== id));
      message.success('Producto eliminado exitosamente');
    } catch (error) {
      console.error('Error deleting product:', error);
      setError('Error deleting product');
      message.error('Error al eliminar el producto');
    }
  };

  if (loading) return <p>Cargando productos...</p>;
  if (error) return <p>{error}</p>;
console.log(products)
  return (
    <section>
      <div className="container">
        <div className="edit-products-page mt-20 mb-75">
        <h2 className='txt-align-center mtb-20'>{editingProduct ? 'Actualizar Producto' : 'Agregar Nuevo Producto'}</h2>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              style={{ maxWidth: 600 }}
              onFinish={editingProduct ? handleUpdateProduct : handleAddProduct}
            >
              <Form.Item label="Nombre del Producto" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <div className='f-h jc-sb'>
              <Form.Item label="Precio" name="price" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Cantidad" name="pieces" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
              </div>
              <div className='f-h jc-sb'>
              <Form.Item label="Imagen" valuePropName="fileList">
                <Upload beforeUpload={() => false} onChange={handleFileChange}>
                  <Button icon={<PlusOutlined />}>Subir imagen</Button>
                </Upload>
              </Form.Item>
              <Form.Item label=" ">
                <Button type="primary" htmlType="submit">
                  {editingProduct ? 'Actualizar Producto' : 'Agregar Producto'}
                </Button>
              </Form.Item>
              </div>
          </Form>
            <ul>
              {products.map(product => (
                <div className='card-white edit-products-card'>
                  <li key={product.id}>
                    <img className='edit-products-image
                    ' src={product.image_url} alt="Imagen del Producto" />
                    <div className='bold'>{product.name}</div>
                    <div>{product.pieces} unidades</div>
                    <Button onClick={() => handleEditProduct(product)}>Editar</Button>
                    <Button danger onClick={() => handleDeleteProduct(product.id)}>Eliminar</Button>
                  </li>
                </div>
              ))}
            </ul>
            
        </div>
      </div>
    </section>
  );
};

export default EditProductsPage;