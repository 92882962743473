import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../components/supabaseClient';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserProfile = async (user) => {
    try {
      console.log('Fetching profile for user:', user);
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('user_role, full_name, phone')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error al obtener el perfil:', error);
        return null;
      }

      return { ...user, role: profile.user_role, full_name: profile.full_name, phone: profile.phone };
    } catch (error) {
      console.error('Error general:', error);
      return null;
    }
  };

  const checkSession = async () => {
    console.log('Verificando la sesión...');
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) {
      console.error('Error obteniendo la sesión:', error);
      setUser(null);
    } else if (session) {
      console.log('Sesión encontrada:', session);
      setUser(session.user);
      fetchUserProfile(session.user).then((userWithProfile) => {
        setUser(userWithProfile);
        console.log('Perfil del usuario cargado:', userWithProfile);
      });
    } else {
      console.log('No hay sesión encontrada');
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log('useEffect ejecutándose');
    checkSession();
  }, []);

  const signUp = async (email, password, additionalData) => {
    const { user, error } = await supabase.auth.signUp({
      email,
      password,
      data: additionalData,
    });
    if (error) {
      throw error;
    }
    return user;
  };

  const signIn = async (email, password) => {
    try {
      setLoading(true);
      console.log('Iniciando sesión...');
      const { data: { user }, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;

      console.log('Usuario autenticado:', user);
      setUser(user);
      fetchUserProfile(user).then((userWithProfile) => {
        setUser(userWithProfile);
        console.log('Usuario con perfil:', userWithProfile);
        setLoading(false);
      });

    } catch (err) {
      console.error('Error durante el inicio de sesión:', err.message);
      setLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      setUser(null);
      console.log('Cierre de sesión exitoso, redirigiendo...');
      window.location.href = '/login';
    } catch (err) {
      console.error('Error durante el cierre de sesión:', err.message);
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, signUp }}>
      {loading ? <p>Cargando...</p> : children}
    </AuthContext.Provider>
  );
};