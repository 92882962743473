import React from 'react';
import { useCart } from '../../context/cartContext';
import { useAuth } from '../../context/authContext';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'; // Asegúrate de usar moment

const OrderSummary = ({ details }) => {
    const { cart, clearCart } = useCart();
    const navigate = useNavigate();
    const { user } = useAuth();

    const methodTranslation = {
        'store-pickup': 'Recoger en tienda',
        'foreign-shipping': 'Envío foráneo',
        'home-delivery': 'Envío a domicilio',
        'pickup-point': 'Entrega en punto fijo'
    };

    const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const shippingCost = details.method === 'store-pickup' ? 0 : details.method === 'foreign-shipping' ? 50 : 100;
    const total = subtotal + shippingCost;

    // Aquí nos aseguramos de que la fecha esté bien formateada usando moment
    const formattedDeliveryDate = details.deliveryDate
    ? moment(details.deliveryDate).format('dddd, MMMM Do YYYY')
    : 'Fecha no disponible';

    const handleGoBack = () => {
        navigate('/cart');
    };

    const saveOrderToDatabase = async () => {
        try {
            // Lógica para guardar el pedido en la base de datos
            clearCart();

            navigate('/order-placed', {
                state: {
                    orderDetails: {
                        items: cart,
                        delivery_date: formattedDeliveryDate,
                        store: details.store, // Pasar la tienda seleccionada
                        address: details.address, // Pasar la dirección seleccionada
                    },
                    user,
                    formattedDeliveryDate,
                },
            });
        } catch (error) {
            console.error('Error al registrar el pedido:', error.message);
            window.alert(`Error: ${error.message}`);
        }
    };

    return (
        <section className='mt-20 mb-75'>
            <div className="container mtb-20">
                <button className="btn btn-yellow" onClick={handleGoBack}>
                    Regresar
                </button>
                <div className='total-card bold color-grey'>
                    <div className='mb-16'>Subtotal</div>
                    <div className='txt-align-rigth'>${subtotal.toFixed(2)}</div>
                    <div className='mb-16'>Envio</div>
                    <div className='txt-align-rigth'>
                        {shippingCost === 0 ? 'Gratis' : `$${shippingCost.toFixed(2)}`}
                    </div>
                    <div className='color-black'>Total</div>
                    <div className='txt-align-rigth color-black'>${total.toFixed(2)}</div>
                </div>
                <div className='card-white'>
                    <h2 className='mb-16'>{methodTranslation[details.method] || details.method}</h2>
                    <div className='bold mb-16'>Nombre y apellido:</div>
                    <p className='mb-16'>{user?.user_metadata?.full_name || 'No disponible'}</p>
                    <div className='bold mb-16'>Teléfono de contacto:</div>
                    <p className='mb-16'>{user.phone || 'No disponible'}</p>
                    <div className='bold mb-16'>Fecha de entrega:</div>
                    <p className='mb-16'>{formattedDeliveryDate}</p>
                    {details.store && (
                        <>
                            <div className='bold mb-16'>Recoger en:</div>
                            <p className='mb-16'>{details.store}</p>
                        </>
                    )}
                    {details.address && (
                        <>
                            <div className='bold mb-16'>Dirección de entrega:</div>
                            <p className='mb-16'>{details.address}</p>
                        </>
                    )}
                </div>
                <ul>
                    {cart.map(item => (
                        <li className='card-white f-h mtb-16' key={item.id}>
                            <div className='item-image-wrapper'>
                                <img className='item-image' src={item.image_url} alt={item.name} />
                            </div>
                            <div className='card-description'>
                                <h3 className='mb-16'>{item.name}</h3>
                                <p>Costo unitario: ${item.price}</p>
                                <div className='f-h jc-sb bold mt-90'>
                                    Cantidad: {item.quantity}
                                    <p>${(item.quantity * item.price).toFixed(2)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <button className='btn mtb-20' onClick={saveOrderToDatabase}>Finalizar compra</button>
            </div>
        </section>
    );
};

export default OrderSummary;