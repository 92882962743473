import React, { useState, useEffect } from 'react';
import { Button, Modal, DatePicker } from 'antd'; // Importamos DatePicker
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import DropdownForeign from './DropdownForeign';
import DropdownDelivery from './DropDownDelivery';
import DropDownPickUp from './DropDownPickUp';
import moment from 'moment'; // Importa moment.js para manejar fechas

const ShippingMethod = ({ onNext }) => {
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedStore, setSelectedStore] = useState(''); 
    const [selectedAddress, setSelectedAddress] = useState(''); 
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para el modal
    const [deliveryDate, setDeliveryDate] = useState(''); // Estado para la fecha de entrega (solo para paquetería o recoger en tienda)
    const navigate = useNavigate();
    const { user } = useAuth();
    const [name, setName] = useState(user ? user.user_metadata.full_name : '');

    // Cálculo de fecha para envío foráneo
    useEffect(() => {
        if (selectedMethod === 'foreign-shipping' && selectedAddress) {
            const today = moment();
            let deliveryDate = moment(today); 
    
            if (selectedAddress.includes('Actopan')) {
                // Encontrar el próximo jueves
                while (deliveryDate.day() !== 4) {
                    deliveryDate.add(1, 'days');
                }
            } else if (selectedAddress.includes('Tulancingo') || selectedAddress.includes('Cd. Sahagún')) {
                // Encontrar el próximo viernes
                while (deliveryDate.day() !== 5) {
                    deliveryDate.add(1, 'days');
                }
            }
            
            console.log("Dirección seleccionada:", selectedAddress);
            console.log("Fecha de entrega calculada:", deliveryDate.format('dddd, MMMM Do YYYY'));
    
            setDeliveryDate(deliveryDate); // Guardar la fecha de entrega
        }
    }, [selectedMethod, selectedAddress]);

    useEffect(() => {
        if (selectedMethod === 'pickup-point') {
            const today = moment();
            let deliveryDay = moment(today);
    
            // Encontrar el próximo martes
            while (deliveryDay.day() !== 2) {
                deliveryDay.add(1, 'days');
            }
    
            // Establecer la hora a las 12 PM
            deliveryDay.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
            setDeliveryDate(deliveryDay);
    
            console.log("Fecha de entrega para punto de entrega:", deliveryDay.format('dddd, MMMM Do YYYY, h:mm A'));
        }
    }, [selectedMethod]);

    // Función para deshabilitar fechas anteriores a hoy y domingos (para método de paquetería)
    const disabledDate = (current) => {
        const tooLate = current && current > moment().add(3, 'days');
        const tooEarly = current && current < moment().endOf('day');
        const isSunday = current && current.day() === 0; // Deshabilitar domingos
        return tooEarly || tooLate || isSunday;
    };

    const calculateDeliveryDate = (address) => {
        const today = moment();
        let deliveryDay = today;

        if (address === 'Actopan') {
            // Encontrar el próximo jueves
            while (deliveryDay.day() !== 4) {
                deliveryDay.add(1, 'days');
            }
        } else if (address === 'Tulancingo' || address === 'CD. Sahagún') {
            // Encontrar el próximo viernes
            while (deliveryDay.day() !== 5) {
                deliveryDay.add(1, 'days');
            }
        }

        setDeliveryDate(deliveryDay); // Asignar la fecha de entrega calculada
    };

    // Función para calcular el siguiente día hábil (excepto domingos)
    const getNextBusinessDay = () => {
        const today = moment();
        let nextDay = today.add(1, 'days');
        if (nextDay.day() === 0) {
            // Si el próximo día es domingo, mover al lunes
            nextDay = nextDay.add(1, 'days');
        }
        return nextDay;
    };

    const handleSelectMethod = (method) => {
        setSelectedMethod(method);
        if (method === 'store-pickup') {
            const nextBusinessDay = getNextBusinessDay();
            setDeliveryDate(nextBusinessDay); // Asignar la fecha de entrega para "Recoger en tienda"
        } else if (method !== 'package-shipping') {
            setDeliveryDate(''); // Restablecer la fecha de entrega si no es paquetería ni recoger en tienda
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!user) {
            setIsModalOpen(true);
        } else {
            if (selectedMethod) {
                onNext({
                    method: selectedMethod,
                    name,
                    store: selectedStore,
                    address: selectedAddress,
                    deliveryDate: deliveryDate ? deliveryDate.format('YYYY-MM-DD') : null,
                });
                navigate('/order-summary');
            }
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleGoBack = () => {
        navigate(-1); // Redirige a la página anterior en el historial
    };

    const handleOk = () => {
        navigate('/login', { state: { from: '/cart' } });  // Redirige al login y guarda la página de origen
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleStoreSelect = (store) => setSelectedStore(store);
    const handleAddressSelect = (address) => setSelectedAddress(address);

    return (
        <section>
            <div className="container mb-75">
                <div className='shipping-method mtb-20'>
                    <button className='btn btn-yellow mb-16' onClick={handleGoBack}>
                        Regresar
                    </button>
                    <h2 className='mb-16'>Elige tu forma de entrega</h2>

                    {/* Método de envío - Recoger en tienda */}
                    <div
                        className={`shipping-option ${selectedMethod === 'store-pickup' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('store-pickup')}
                    >
                        <div className='first-wrapper'>
                            <span>Recoger en tienda</span>
                            <div className='method_price'>
                                Gratis
                            </div>
                        </div>
                        {selectedMethod === 'store-pickup' && (
                            <form className='store-pickup'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                    {/* Método de envío - Envío foráneo */}
                    <div
                        className={`shipping-option ${selectedMethod === 'foreign-shipping' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('foreign-shipping')}
                    >
                        <div className='first-wrapper'>
                            <span>Envío foráneo</span>
                            <div className='method_price'>
                                $50.00
                            </div>
                        </div>
                        {selectedMethod === 'foreign-shipping' && (
                            <form className='foreign-shipping'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    <p className='mb-16'> 
                                        Entregar en:
                                    </p>
                                    <DropdownForeign onSelect={handleAddressSelect} />
                                </div>
                                <p>Fecha de entrega: {deliveryDate ? deliveryDate.format('dddd, MMMM Do YYYY') : 'Seleccione una dirección'}</p>
                            </form>
                        )}
                    </div>
                    {/* Método de envío - Punto de entrega */}
                    <div
                        className={`shipping-option ${selectedMethod === 'pickup-point' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('pickup-point')}
                    >
                        <div className='first-wrapper'>
                            <span>Punto de entrega</span>
                            <div className='method_price'>
                                Gratis
                            </div>
                        </div>
                        {selectedMethod === 'pickup-point' && (
                            <form className='pickup-point'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    <p className='mb-16'> 
                                        Selecciona un punto de entrega:
                                    </p>
                                    <DropDownPickUp onSelect={handleAddressSelect} />
                                </div>
                                <p>Fecha de entrega: {deliveryDate ? deliveryDate.format('dddd, MMMM Do YYYY') : 'Seleccione una dirección'}</p>
                            </form>
                        )}
                    </div>

                    {/* Método de envío - Paquetería con calendario */}
                    <div
                        className={`shipping-option ${selectedMethod === 'package-shipping' ? 'selected' : ''}`}
                        onClick={() => handleSelectMethod('package-shipping')}
                    >
                        <div className='first-wrapper'>
                            <span>Paquetería</span>
                            <div className='method_price'>
                                $350.00
                            </div>
                        </div>
                        {selectedMethod === 'package-shipping' && (
                            <form className='package-shipping'>
                                <div>
                                    <label className='mtb-16'>Nombre y apellido</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Nombre y apellido"
                                        required
                                    />
                                    {/* DatePicker para seleccionar fecha de entrega solo en Paquetería */}
                                    <DatePicker
                                        format="dddd, MMMM Do YYYY" // Formato de la fecha
                                        disabledDate={disabledDate} // Deshabilitar fechas no válidas
                                        onChange={(date) => setDeliveryDate(date)} // Actualizar fecha seleccionada
                                    />
                                    {deliveryDate && (
                                        <p>Fecha de entrega seleccionada: {deliveryDate.format('dddd, MMMM Do YYYY')}</p>
                                    )}
                                </div>
                            </form>
                        )}
                    </div>

                    <button
                        className='confirm-button'
                        onClick={handleSubmit}
                        disabled={selectedMethod === 'package-shipping' && !deliveryDate} // Solo deshabilitar si no hay fecha en Paquetería
                    >
                        Confirmar método de envío
                    </button>
                </div>
            </div>


            <Modal title="¡Inicia sesión!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>¡Inicia sesión para finalizar tu compra!</p>
            </Modal>
        </section>
    );
};

export default ShippingMethod;

// import React, { useState } from 'react';
// import { Button, Modal } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../context/authContext';
// import DropdownForeign from './DropdownForeign';
// import DropdownDelivery from './DropDownDelivery';

// const ShippingMethod = ({ onNext }) => {
//     const [selectedMethod, setSelectedMethod] = useState(null);
//     const [selectedStore, setSelectedStore] = useState(''); 
//     const [selectedAddress, setSelectedAddress] = useState(''); 
//     const [isModalOpen, setIsModalOpen] = useState(false); // Estado para el modal
//     const navigate = useNavigate();
//     const { user } = useAuth();
//     const [name, setName] = useState(user ? user.user_metadata.full_name : '');

//     const handleSelectMethod = (method) => {
//         setSelectedMethod(method);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (!user) {
//             setIsModalOpen(true);
//         } else {
//             if (selectedMethod) {
//                 onNext({
//                     method: selectedMethod,
//                     name,
//                     store: selectedStore,
//                     address: selectedAddress,
//                 });
//                 navigate('/order-summary');
//             }
//         }
//     };

//     const showModal = () => {
//         setIsModalOpen(true);
//     };

//     const handleOk = () => {
//         navigate('/login', { state: { from: '/cart' } });  // Redirige al login y guarda la página de origen
//         setIsModalOpen(false);
//     };

//     const handleCancel = () => {
//         setIsModalOpen(false);
//     };

//     const handleStoreSelect = (store) => setSelectedStore(store);
//     const handleAddressSelect = (address) => setSelectedAddress(address);



//     return (
//         <section>
//             <div className="container mb-75">
//                 <div className='shipping-method mtb-20'>
//                     <button className='btn btn-yellow mb-16' onClick={() => navigate('/cart')}>
//                         Regresar
//                     </button>
//                     <h2 className='mb-16'>Elige tu forma de entrega</h2>

//                     <div
//                         className={`shipping-option ${selectedMethod === 'store-pickup' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('store-pickup')}
//                     >
//                         <div className='first-wrapper'>
//                             <span>Recoger en tienda</span>
//                             <div className='method_price'>
//                                 Gratis
//                             </div>
//                         </div>
//                         {selectedMethod === 'store-pickup' && (
//                             <form className='store-pickup'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                 </div>
//                             </form>
//                         )}
//                     </div>

//                     <div
//                         className={`shipping-option ${selectedMethod === 'foreign-shipping' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('foreign-shipping')}
//                     >
//                         <div className='first-wrapper'>
//                             <span>Envío foráneo</span>
//                             <div className='method_price'>
//                                 $50.00
//                             </div>
//                         </div>
//                         {selectedMethod === 'foreign-shipping' && (
//                             <form className='foreign-shipping'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                     <p className='mb-16'> 
//                                         Entregar en:
//                                     </p>
//                                     <DropdownForeign onSelect={handleAddressSelect} />
//                                 </div>
//                             </form>
//                         )}
//                     </div>

//                     <div
//                         className={`shipping-option ${selectedMethod === 'home-delivery' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('home-delivery')}
//                     >
//                         <div className='first-wrapper'>
//                             <span>Envío a domicilio</span>
//                             <div className='method_price'>
//                                 $100.00
//                             </div>
//                         </div>
//                         {selectedMethod === 'home-delivery' && (
//                             <form className='home-delivery'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                     <DropdownDelivery onSelect={handleStoreSelect} />
//                                 </div>
//                             </form>
//                         )}
//                     </div>

//                     <button
//                         className='confirm-button'
//                         onClick={handleSubmit}
//                         disabled={!selectedMethod}
//                     >
//                         Confirmar método de envío
//                     </button>
//                 </div>
//             </div>

//             <Modal title="¡Inicia sesión!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
//                 <p>¡Inicia sesión para finalizar tu compra!</p>
//             </Modal>
//         </section>
//     );
// };

// export default ShippingMethod;