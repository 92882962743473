import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useCart } from '../../context/cartContext';
import { message, Button } from 'antd';

const ProductList = () => {
  const { addItemToCart } = useCart();
  const [products, setProducts] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchProducts = async () => {
      let { data: products, error } = await supabase
        .from('products')
        .select('*');
       if (error) {
         console.error('Error fetching products:', error);
       } else {
         // Ordenar productos: los que tienen más piezas al principio, los agotados al final
         const sortedProducts = products.sort((a, b) => b.pieces - a.pieces);
         setProducts(sortedProducts);
       }
    };
    fetchProducts();
  }, []);

  const handleAddToCart = (product, quantity) => {
    if (product.pieces > 0) {
      addItemToCart(product, quantity);
      messageApi.info(`${product.name} ha sido añadido al carrito!`);
    } else {
      messageApi.warning(`${product.name} está agotado.`);
    }
  };

  const getStatusLabel = (pieces) => {
    if (pieces >= 10) {
        return <div className='product-card-dispo available'>Disponible</div>;
    } else if (pieces > 0 && pieces < 10) {
        return <div className='product-card-dispo limited'>Casi agotado</div>;
    } else {
        return <div className='product-card-dispo sold-out'>Agotado</div>;
    }
  };

  return (
    <>
      {contextHolder}
      <ul className='products-wrapper'>
        {products.map(product => (
          <li 
            className='product-card' 
            key={product.id}
            style={{ cursor: product.pieces === 0 ? 'not-allowed' : 'pointer' }} 
          >
            {getStatusLabel(product.pieces)}
            <h2>{product.name}</h2>
            <div className='product-image'>
              {product.image_url && <img src={product.image_url} alt={product.name} />}
            </div>
            <p>{product.description}</p>
            <p className='product-price'>${product.price}</p>
            <Button 
              className='btn br-8' 
              onClick={() => handleAddToCart(product, 1)}
              disabled={product.pieces === 0}
            >
              Añadir al carrito
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProductList;