import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const PrivateRoute = ({ roles = [] }) => {
  const { user } = useAuth();

  if (!user) {
    // Si el usuario no está autenticado, redirigir a la página de inicio de sesión
    return <Navigate to="/login" />;
  }

  if (roles.length && !roles.includes(user.role)) {
    // Si el rol del usuario no está autorizado, redirigir a la página de inicio
    return <Navigate to="/" />;
  }

  // Si está autenticado y autorizado, permitir el acceso a la ruta
  return <Outlet />;
};

export default PrivateRoute;