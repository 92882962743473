import React from 'react';
import ProductDetail from './productDetail'

const ProductList = () => {

return (
            <div className='product-list mtb-20'>
                <h1>Lo más nuevo</h1>
                <ProductDetail></ProductDetail>
            </div>

);
};

export default ProductList;