import React, { useState } from 'react';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";

const Slider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleWillChange = (e) => {
        setActiveIndex(e.index);
    };

    return (
        <div className='f-h jc-sb gap-16 mtb-20'>
        <div className="slider">
            <Flicking
                className="flicking2"
                circular={true}
                defaultIndex={1}
                onWillChange={handleWillChange}
                align="center"
                autoResize={true}
            >
                <div className="panel2"><img src="/imgs/image1.png" alt="Imagen 1" /></div>
                <div className="panel2"><img src="/imgs/image2.png" alt="Imagen 2" /></div>
                <div className="panel2"><img src="/imgs/image3.png" alt="Imagen 3" /></div>
            </Flicking>
        </div>
        <div className="slider-2">
            <Flicking
                className="flicking2"
                circular={true}
                defaultIndex={1}
                onWillChange={handleWillChange}
                align="center"
                autoResize={true}
            >
                <div className="panel2">oferta 1</div>
                <div className="panel2">oferta 2</div>
                <div className="panel2">oferta3</div>
            </Flicking>
        </div>
        </div>
    );
};

export default Slider;