import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const { signUp, signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Pasar el campo de teléfono al momento del registro
      const { user, error } = await signUp(email, password, { full_name: fullName, phone });
      if (error) throw error;
      console.log('User registered:', user);

      // Iniciar sesión automáticamente
      const { error: signInError } = await signIn(email, password);
      if (signInError) throw signInError;

      // Mostrar notificación de registro exitoso
      toast.success("Registro exitoso! Bienvenido MRT");

      // Redirigir al home después de un pequeño retraso para mostrar la notificación
      setTimeout(() => {
        navigate('/');
      }, 3000); // Ajusta el tiempo según tus necesidades
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <h1 className='txt-align-center mtb-16'>¡Quiero registrarme!</h1>
          <form onSubmit={handleRegister}>
            <p className='mb-5'>Nombre completo:</p>
            <input
              className='register-form'
              type="text"
              placeholder="Juan Pérez Solis"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <p className='mb-5'>Correo:</p>
            <input
              className='register-form'
              type="email"
              placeholder="juan@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <p className='mb-5'>Contraseña:</p>
            <input
              className='register-form'
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <p className='mb-5'>Teléfono:</p>
            <input
              className='register-form'
              type="number"
              placeholder="Ejem. 7711119876"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <button className='btn' type="submit">Registrarse</button>
            {error && <p>{error}</p>}
          </form>
          <ToastContainer />
        </div>
      </section>
    </>
  );
};

export default Register;