import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Asegúrate de importar correctamente tu cliente de Supabase
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { useAuth } from '../../context/authContext';
import { useLocation } from 'react-router-dom';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { user, signIn } = useAuth(); // Asegúrate de usar `signIn` desde el contexto de Auth
  const location = useLocation();
  const from = location.state?.from || '/';  // Por defecto redirige a home si no hay una página anterior

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signIn(email, password);
      setIsModalOpen(true); // Mostrar el modal de login exitoso
    } catch (error) {
      console.error('Error signing in:', error.message);
      setError(error.message);
    }
  };

  const handleModalOk = () => {
    console.log('Cerrando el modal...');
    setIsModalOpen(false);
    console.log('Redirigiendo al home...');
    navigate('/');
  };

  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        handleModalOk(); // Utilizar la función handleModalOk para cerrar el modal y redirigir
      }, 3000); // Ajusta el tiempo según tus necesidades

      return () => clearTimeout(timer);
    }
  }, [isModalOpen]);

  const handleRegister = () => {
    navigate('/register'); // Redirigir a la página de registro
  };

  return (
    <>
      <section>
        <div className="container">
          <h1 className='txt-align-center mtb-16'>Iniciar sesión</h1>
          <form className='login-form' onSubmit={handleLogin}>
            <p className='mb-5'>Correo:</p>
            <input
              className='login-input'
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <p className='mb-5'>Contraseña:</p>
            <input
              className='login-input'
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button className='btn mtb-16' type="submit">Iniciar Sesión</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </form>
          <div className='txt-align-center'> ó </div>
          <button className='btn btn-yellow mtb-16' onClick={handleRegister}>Registrarme</button>
        </div>
      </section>

      {/* Modal para mostrar mensajes */}
      <Modal
        title="Inicio de Sesión Exitoso"
        open={isModalOpen}
        onOk={handleModalOk}
        closable={false}
        footer={null}
      >
        <p>¡Inicio de sesión exitoso! Bienvenido de nuevo.</p>
        <p>Redirigiendo al inicio...</p>
      </Modal>
    </>
  );
};

export default Login;