import React from 'react';
import {  useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
const UserProfile = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();


  if (!user) {
    return <h2>¿Aún no eres cliente?</h2> 
  }

  const handleLogout = async () => {
    console.log('Intentando cerrar sesión...');
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
    }
  };
  console.log(user)
  return (
    <section>
      <div className='container mtb-20'>
        <div className='profile-wrapper'>
          <div>
            <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M64 31C53 31 44 39.8 44 50.4C44 61 53 70 64 70C75 70 84 61.2 84 50.6C84 40 75 31 64 31Z" fill="#AA0000"/>
              <path d="M64.0001 3.6C30.6001 3.6 3.6001 30.6 3.6001 64C3.6001 97.4 30.6001 124.6 64.0001 124.6C97.4001 124.6 124.6 97.4 124.6 64C124.6 30.6 97.4001 3.6 64.0001 3.6ZM97.6001 103V92.8C97.6001 81.4 88.4001 72.2 77.0001 72.2H51.0001C39.6001 72.2 30.4001 81.4 30.4001 92.8V103C19.4001 93.6 12.4001 79.6 12.4001 64C12.6001 35.6 35.6001 12.6 64.0001 12.6C92.4001 12.6 115.6 35.8 115.6 64.2C115.6 79.6 108.6 93.6 97.6001 103Z" fill="#AA0000"/>
            </svg>
          </div>
          <div>
            <div className='mb-10'>
              <span className='bold'>{user?.user_metadata.full_name}</span>
            </div>
            <div className='mb-10'>
              <span> {user.email}</span>
            </div>
            <div className='mb-10'>
              <span> {user.phone}</span>
            </div>
            <span className='profile-rol'>{user.role === 'admin' ? 'Administrador' : 'Mi Perfil'}</span>
          </div>
        </div>
        <button className='btn-edit-profile'>Editar Perfil</button>
        <button className='btn-my-orders'>Mis Pedidos</button>
        <button className='btn-change-password'>Cambiar Contraseña</button>
        <button className="btn" onClick={handleLogout}>
          <p>Cerrar Sesión</p>
        </button>
      </div>
    </section>
  );
};

export default UserProfile;