import React, { useState } from 'react';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(1);  // Empezamos con el índice 1 como default

    const handleWillChange = (e) => {
        setActiveIndex(e.index);
    };

    return (
        <>
            <h1 className='slider-title'>Redes Sociales</h1>
            {/* <Flicking
                className="flicking"
                circular={true}
                defaultIndex={1}
                onWillChange={handleWillChange}
            >
                {[video1, video2, video3, video1, video2, video3].map((video, index) => (
                    <div key={index} className={`panel ${index === activeIndex ? "active" : ""}`}>
                        <video src={video} controls />
                    </div>
                ))}
            </Flicking> */}
            
        </>
    );
};

export default Carousel;