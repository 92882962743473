import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductList from './components/components/products/productList';
import ProductDetail from './components/components/products/productDetail';
import Navbar from './navbar';
import Home from './pages/Home';
import Cart from './components/components/cart/cart';
import NotFound from './pages/404';
import AdminPage from './components/components/AdminPage';
import Login from './components/components/auth/Login';
import Register from './components/components/auth/Register';
import PrivateRoute from './components/components/PrivateRoute';
import { AuthProvider } from './components/context/authContext';
import { CartProvider } from './components/context/cartContext';
// import ProtectedComponent from "./components/components/ProtectedComponent";
import ShippingMethod from './components/components/orders/ShippingMethod';
import OrderSumary from './components/components/orders/OrderSumary';
import OrderPlaced from './components/components/orders/OrderedPlaced';
import UserProfile from './components/components/UserProfile.jsx';
// import AdminProducts from './AdminProducts';
import EditProductsPage from './EditProductsPage'

const App = () => {
  const [orderDetails, setOrderDetails] = useState({});

  const handleNext = (details) => {
    setOrderDetails(details);
    console.log("Order details:", details);
  };
  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <div>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<ProductList />} />
              <Route path="/product/:id" element={<ProductDetail />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<NotFound />} />
              <Route element={<PrivateRoute roles={['admin']} />}>
              <Route path="/admin" element={<AdminPage />} />
                <Route path="/admin/edit-products" element={<EditProductsPage />} />
              </Route>
              <Route element={<PrivateRoute roles={['client', 'admin']} />}>
                <Route path="/profile" element={<UserProfile />} />
              </Route>
              <Route path="/shipping-method" element={<ShippingMethod onNext={handleNext} />} />
              <Route path="/order-summary" element={<OrderSumary details={orderDetails} />} />
              <Route path="/order-placed" element={<OrderPlaced />} />
            </Routes>
          </div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
};


export default App;