import ProductList from '../components/components/products/productList.jsx';
import Footer from '../components/components/Footer.jsx';
import VideoSlider from '../Slider-home.jsx';
import React, { useEffect } from 'react';
import { useAuth } from '../components/context/authContext';
import { Link } from 'react-router-dom';
import HeroSlider from '../components/components/HeroSlider.jsx' 

const Home = () => {
  const { user } = useAuth();

  const userName = user?.user_metadata?.full_name ?? 'Usuario';

  // useEffect(() => {
  //   if (user) {
  //     const timer = setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [user]);

return (
  <>
  <section>
  <div className='container'>
    {user?.role === 'client' && (
      <>
        <HeroSlider className="mtb-20" />
        <ProductList />

      </>
    )}
    {user?.role === 'admin' && (
      <>
        <h1 className='txt-align-center mtb-20'>¡Bienvenido {userName}!</h1>
        <Link to="/admin/edit-products">
          <button className="btn btn-admin-access">
            Ir al Panel de Administración
          </button>
        </Link>
        <Link className='btn' to="/admin">Ver Historial de Pedidos</Link>
        <ProductList />

      </>
    )}
    {!user?.role && (
      <>
        <HeroSlider />
        <ProductList />
      </>
    )}
    
  </div>
</section>
  <VideoSlider />
  <Footer />
</>
  );
};

export default Home;