import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';

const items = [
    { key: '1', label: 'Actopan (Jueves)' },
    { key: '2', label: 'Tulancingo (Viernes)' },
    { key: '3', label: 'Cd. Sahagún (Viernes)' },
];

const DropdownForeign = ({ onSelect }) => {
    const [selectedItem, setSelectedItem] = useState('Seleccionar');

    const handleMenuClick = (e) => {
        const selectedLabel = items.find(item => item.key === e.key).label;
        setSelectedItem(selectedLabel);
        onSelect(selectedLabel); // Envía la selección al padre
    };

    return (
        <Dropdown
            menu={{
                items,
                onClick: handleMenuClick,
            }}
        >
            <Typography.Link>
                <Space>
                    {selectedItem}
                    <DownOutlined />
                </Space>
            </Typography.Link>
        </Dropdown>
    );
};

export default DropdownForeign;