import React, { useEffect, useState } from 'react';
import { supabase } from '../components/supabaseClient';
import { jsPDF } from 'jspdf';


const AdminPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const { data, error } = await supabase
          .from('orders')
          .select('*, profiles(full_name, phone)');

        if (error) {
          throw error;
        }

        setOrders(data);
      } catch (error) {
        console.error('Error al obtener los pedidos:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const translateMethod = (method) => {
    switch (method) {
      case 'store-pickup':
        return 'Recoger en tienda';
      case 'home-delivery':
        return 'Entrega a domicilio';
      case 'foreign-shipping':
        return 'Envío internacional';
      default:
        return method;
    }
  };

  const renderItems = (items) => {
    let parsedItems;
    
    if (typeof items === 'string') {
        try {
            parsedItems = JSON.parse(items);
        } catch (error) {
            console.error("Error al parsear los items:", error);
            return <p>Error al cargar items</p>;
        }
    } else {
        parsedItems = items;
    }

    return (
        <ul>
          {parsedItems.map((item, index) => (
            <li key={index}>
              {item.name} - {item.quantity} unidades - ${item.price}
            </li>
          ))}
        </ul>
    );
};

  const printOrder = (order) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Orden ${order.folio}</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h1 { font-size: 24px; }
            p { font-size: 18px; }
            ul { font-size: 16px; }
          </style>
        </head>
        <body>
          <h1>Orden: ${order.folio}</h1>
          <p><strong>Cliente:</strong> ${order.profiles ? order.profiles.full_name : "Nombre no disponible"}</p>
        
          <p><strong>Fecha:</strong> ${new Date(order.created_at).toLocaleString()}</p>
          <p><strong>Total:</strong> $${order.total}</p>
          <p><strong>Método de Envío:</strong>${translateMethod(order.method)}</p>
          <h2>Items</h2>
          ${renderItems(order.items).props.children.map(item => `<p>${item.props.children.join(' ')}</p>`).join('')}
          <p><strong>Subtotal:</strong> $${order.subtotal}</p>
          <p><strong>Costo de Envío:</strong> $${order.shipping_cost}</p>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleUploadPdf = async (order) => {
    const doc = new jsPDF({
      format: 'a4', // Tamaño A4
      unit: 'mm',   // Unidades en milímetros
    });
    const content = `
      <div class="ticket-logo">
        <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/MRT.png" alt="Descripción de la imagen"/>
      </div>
      <h1 class="border" style="margin-bottom:20px;">Orden:${order.folio}</h1>
      <table style="width: 100%; border-collapse: collapse;">
        <tr>
            <td style="padding: 8px; border: 1px solid #ddd;">Nombre</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${order.profiles ? order.profiles.full_name : "Nombre no disponible"}</td>
        </tr>
        <tr>
            <td style="padding: 8px; border: 1px solid #ddd;">Fecha</td>
            <td style="padding: 8px; border: 1px solid #ddd;">${new Date(order.created_at).toLocaleString()}</td>
        </tr>
        <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">Método de Envío</td>
        <td style="padding: 8px; border: 1px solid #ddd;">${translateMethod(order.method)}</td>
        </tr>
        <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">Subtotal</td>
        <td style="padding: 8px; border: 1px solid #ddd;">$${order.subtotal}</td>
        </tr>
        <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">Costo de Envío</td>
        <td style="padding: 8px; border: 1px solid #ddd;">$${order.shipping_cost}</td>
        </tr>
        <tr>
            <td style="padding: 8px; border: 1px solid #ddd;">Total</td>
            <td style="padding: 8px; border: 1px solid #ddd;">$${order.total}</td>
        </tr>
    </table>
    `;

    doc.html(content, {
        callback: async function (doc) {
            // Guardar el PDF localmente
            doc.save(`order_${order.folio}.pdf`);

            // Convertir el PDF a un Blob
            const pdfBlob = doc.output('blob');

            // Subir el PDF al bucket de Supabase
            const { data, error } = await supabase
                .storage
                .from('order-files')
                .upload(`pedido_${order.folio}.pdf`, pdfBlob, {
                    cacheControl: '3600',
                    upsert: false
                });

            if (error) {
                console.error("Error al subir el archivo:", error);
            } else {
                console.log("Archivo subido exitosamente:", data);
            }
        },
        x: 10,
        y: 10,
        width: 180, // Esto asegura que el contenido se ajuste a un ancho de página A4
        windowWidth: 800, // Ajusta el ancho del viewport para aplicar estilos correctamente    
    });
};
  if (loading) return <p>Cargando pedidos...</p>;
  return (
      <section>
        <div className="container">
          <div>
            <h1 className='txt-align-center mtb-20'>Lista de Pedidos</h1>
            {orders.length > 0 ? (
              orders.map((order) => (
                <div className='card-white mb-15' key={order.id}>
                  <p className='bold'>Folio: {order.folio}</p>
                  <p>Total: ${order.total}</p>
                  <p>Fecha del pedido:{new Date(order.created_at).toLocaleString()}</p>
                  <p>{translateMethod(order.method)}</p>
                  <p className='bold'>{order.profiles ? order.profiles.full_name : "Nombre no disponible"}</p>
                  <div>{renderItems(order.items)}</div>
                  <div className='f-h gap-5 mt-20'>
                    <button className='btn' onClick={() => printOrder(order)}>Imprimir</button>
                    <button 
                      className='btn btn-whatsapp' 
                      onClick={() => window.open(`https://wa.me/${order.profiles?.phone}?text=Hola ${order.profiles?.full_name}, tu pedido con folio ${order.folio} está en proceso.`)}
                    >
                      Enviar Whatsapp
                    </button>
                    <button className='btn' onClick={() => handleUploadPdf(order)}>Subir PDF</button>
                  </div>
                </div>
              ))
            ) : (
              <p>No hay pedidos disponibles.</p>
            )}
          </div>
        </div>
      </section>
    );
};

export default AdminPage;