import React, { createContext, useContext, useEffect, useState } from 'react';

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
    const [timer, setTimer] = useState(300); // 5 minutos en segundos
    const [cartStarted, setCartStarted] = useState(false);

    useEffect(() => {
        let interval;
        if (cartStarted) {
            interval = setInterval(() => {
                setTimer(prev => {
                    if (prev === 1) {
                        clearInterval(interval);
                        // Aquí podrías vaciar el carrito también
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [cartStarted]);

    const startTimer = () => {
        setCartStarted(true);
    };

    return (
        <TimerContext.Provider value={{ timer, startTimer }}>
            {children}
        </TimerContext.Provider>
    );
};