import React from 'react';
import { useCart } from '../../context/cartContext';

const ProductCounter = ({ product }) => {
    const { cart, setCart, removeItemFromCart } = useCart();

    const handleDecrease = () => {
        const updatedCart = cart.map(item => 
            item.id === product.id ? { ...item, quantity: item.quantity - 1 } : item
        );

        const itemInCart = updatedCart.find(item => item.id === product.id);

        if (itemInCart.quantity <= 0) {
            removeItemFromCart(product.id);
        } else {
            setCart(updatedCart);
        }
    };

    const handleIncrease = () => {
        const updatedCart = cart.map(item =>
            item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
        setCart(updatedCart);
    };

    return (
        <div className="product-counter">
            <button onClick={handleDecrease}>-</button>
            <span>{product.quantity}</span>
            <button onClick={handleIncrease}>+</button>
        </div>
    );
};

export default ProductCounter;